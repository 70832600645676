import Webcam from "react-webcam";
import React, { useState, useRef, useCallback, useEffect } from "react";
import { isMobile } from 'react-device-detect';

const CustomWebcam = ({ onImageCapture }) => {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [useBackCamera, setUseBackCamera] = useState(isMobile);
  const [deviceId, setDeviceId] = useState(null);
  const [cameras, setCameras] = useState([]);
  const [hasFlash, setHasFlash] = useState(false);
  const [isFlashOn, setIsFlashOn] = useState(false);
  const [focusMode, setFocusMode] = useState('continuous');
  const [zoom, setZoom] = useState(1);

  const videoConstraints = {
    width: isMobile ? { ideal: 1280 } : { ideal: 1280 },
    height: isMobile ? { ideal: 1280 } : { ideal: 1280 },
    facingMode: useBackCamera ? "environment" : "user",
    deviceId: deviceId ? { exact: deviceId } : undefined,
    advanced: [{
      focusMode: focusMode,
      zoom: zoom,
      torch: isFlashOn,
      autoFocus: focusMode === 'continuous',
      exposureMode: 'continuous',
      whiteBalance: 'continuous'
    }]
  };

  useEffect(() => {
    async function setupCamera() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        setCameras(videoDevices);
        
        if (isMobile && useBackCamera) {
          const backCamera = videoDevices.find(device => 
            device.label.toLowerCase().includes('back') ||
            device.label.toLowerCase().includes('environment')
          );
          if (backCamera) {
            setDeviceId(backCamera.deviceId);
            
            const stream = await navigator.mediaDevices.getUserMedia({
              video: { deviceId: backCamera.deviceId }
            });
            const track = stream.getVideoTracks()[0];
            const capabilities = track.getCapabilities();
            setHasFlash('torch' in capabilities);
          }
        }
      } catch (error) {
        console.error('Camera setup error:', error);
      }
    }

    setupCamera();
  }, [useBackCamera]);

  const toggleFlash = useCallback(() => {
    if (hasFlash) {
      setIsFlashOn(!isFlashOn);
      if (webcamRef.current?.video?.srcObject) {
        const track = webcamRef.current.video.srcObject.getVideoTracks()[0];
        track.applyConstraints({
          advanced: [{ torch: !isFlashOn }]
        });
      }
    }
  }, [hasFlash, isFlashOn]);

  const toggleFocus = useCallback(() => {
    setFocusMode(prev => prev === 'continuous' ? 'manual' : 'continuous');
  }, []);

  const handleZoom = useCallback(async (value) => {
    setZoom(value);
    try {
      if (webcamRef.current?.video?.srcObject) {
        const track = webcamRef.current.video.srcObject.getVideoTracks()[0];
        const capabilities = track.getCapabilities();
        if (capabilities?.zoom) {
          await track.applyConstraints({
            advanced: [{ zoom: value }]
          });
        }
      }
    } catch (error) {
      console.warn('Zoom not supported:', error);
    }
  }, []);

  const capture = useCallback(() => {
    const base64Image = webcamRef.current?.getScreenshot();
    if (base64Image) {
      setImgSrc(base64Image);
      const base64ToBlob = (base64) => {
        const byteString = atob(base64.split(',')[1]);
        const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
      };
      onImageCapture(base64ToBlob(base64Image));
    }
  }, [webcamRef, onImageCapture]);

  const retake = () => setImgSrc(null);

  return (
    <div className="webcam-container">
      {imgSrc ? (
        <>
          <img 
            src={imgSrc} 
            alt="captured" 
            className="captured-img"
            style={{ width: '100%', height: 'auto' }}
          />
          <button className="button" onClick={retake}>
            Retake
          </button>
        </>
      ) : (
        <div className="camera-controls">
          <div className="webcam-wrapper" style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}>
            <Webcam
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              screenshotQuality={0.8}
              videoConstraints={videoConstraints}
              onUserMediaError={console.error}
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
          <div className="controls-wrapper">
            {hasFlash && (
              <button 
                className={`control-btn ${isFlashOn ? 'active' : ''}`}
                onClick={toggleFlash}
              >
                Flash
              </button>
            )}
            <button 
              className={`control-btn ${focusMode === 'continuous' ? 'active' : ''}`}
              onClick={toggleFocus}
            >
              AutoFocus
            </button>
            <input
              type="range"
              min="1"
              max="10"
              step="0.1"
              value={zoom}
              onChange={(e) => handleZoom(parseFloat(e.target.value))}
              className="zoom-slider"
            />
            <button className="capture-btn" onClick={capture}>
              Capture
            </button>
          </div>
        </div>
      )}

      <style jsx>{`
        .camera-controls {
          position: relative;
          width: 100%;
        }
        
        .controls-wrapper {
          position: absolute;
          bottom: 20px;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          gap: 10px;
          padding: 10px;
          background: rgba(0, 0, 0, 0.5);
        }
        
        .control-btn {
          padding: 8px 16px;
          border-radius: 20px;
          border: none;
          background: white;
          opacity: 0.8;
        }
        
        .control-btn.active {
          background: #007bff;
          color: white;
        }
        
        .zoom-slider {
          width: 100px;
          margin: 0 10px;
        }
        
        .capture-btn {
          padding: 10px 20px;
          border-radius: 25px;
          background: #ff4444;
          color: white;
          border: none;
        }
      `}</style>
    </div>
  );
};

export default CustomWebcam;
